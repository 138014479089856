import React from 'react'
import ProcessCard from './Components/Card'
import { Container, Row, Col } from 'react-bootstrap'
import Process from "./style"
// import Steps from "~data/it/Process"

const Steps = [
  {
    id:"ps1",
    icon: "fas fa-users",
    iconBackground: "#000",
    title: "Agile, Scrum, CMMi, Nivel 3",
    text:
      "Part of what Adobe does is advise customers about how to transform, to be more",
  },
  {
    id:"ps2",
    icon: "fas fa-user-check",
    iconBackground: "#000",
    title: "Diseño y experiencia de usuario",
    text:
      "Part of what Adobe does is advise customers about how to transform, to be more",
  },
  {
    id:"ps3",
    icon: "fas fa-file-code",
    iconBackground: "#000",
    title: "Java, .Net Core Angular, React, Django, y otros",
    text:
      "Part of what Adobe does is advise customers about how to transform, to be more",
  },
  {
    id:"ps4",
    icon: "fas fa-database",
    iconBackground: "#000",
    title: "Api, REST, SOAP",
    text:
      "Part of what Adobe does is advise customers about how to transform, to be more",
  }
]

export default function ProcessSection({...rest }){
return(
<Process backgroundColor="#F7F7F7">
  <Container>
    <Process.Box  pb="30p" pbXL="50px">
      <Row>
        <Col xs="12" className="text-center">
            {/* <Process.Subtitle as="h5">Our Process</Process.Subtitle> */}
            <Process.Title as="h2">Nuestros servicios de Desarrollo de Software consideran:</Process.Title>
        </Col>
      </Row>
    </Process.Box>
    <Process.Widgets as="div">
    <Row className="justify-content-center">
        {Steps.map(({title,text,icon,iconBackground, id})=>{
             return(
            <Col xs="12" className="col-lg-3 col-md-3 col-xs-6 single-widget text-center"  key={id}>
                <ProcessCard icon={icon} iconBackground={iconBackground} title={title} />
            </Col>
         )
        })}
    </Row>
    </Process.Widgets>
  </Container>
</Process>

)
}