import React from "react"

export default function Chart() {
  return (
    <div className="bg_gray">
      <div className="container service_container">
      <div className="row">
        <div className="col-xs-12 col-md-6 info_chart">
          <small>Importante</small>
          <h5>Proveedores</h5>
          <p>
            Contamos con desarrollo de aplicaciones e implementación de
            proyectos de alta complejidad, impacto y envergadura, demostrando
            con ello que nuestra estrategia, capital humano, metodología de
            trabajo y de desarrollo son de alta calidad basados en las mejores
            prácticas de la industria.
          </p>
          <br />
          <div class="d-flex">
            <i class="fas fa-check-circle"></i>
            <p>
              Ofrecemos soluciones <br /> ORACLE, 100% en la nube:
            </p>
          </div>
          <ul>
            <li>Marketing Cloud</li>
            <li>Sales Cloud</li>
            <li>CPQ Cloud</li>
            <li>Loyalty Cloud</li>
            <li>Commerce</li>
            <li>Service Cloud</li>
            <li>Field Service</li>
          </ul>
        </div>
        <div className="col-xs-12 col-md-6">
          <img
            className="w-100"
            src="https://res.cloudinary.com/dv10simqm/image/upload/v1642105389/helios/Desarrollo%20de%20Software/grafica_software_web_esp_2x_simxmz.png"
          />
        </div>
      </div>
      <div className="last_txt">
        <h4>
          En Helios Digital Group utilizamos tenemos amplia experiencia en el
          desarrollo de software para asistir a nuestros clientes, tenemos
          equipos de trabajo altamente efectivos, que les facilitan las
          tecnologías más modernas y metodologías ágiles para lograr sus
          objetivos en tiempo y acorde a las expectativas.
        </h4>
        <br />
        <h4>
          <span>Soluciones de Nube y Seguridad:</span> Soporte completo de tu
          nube basada en agentes nativos que permite realizar respaldos y
          recuperaciones de información granularmente. La arquitectura en la
          nube es una alternativa flexible aportando valores de escalabilidad,
          rendimiento, costos y un acceso más rápido a la innovación.
        </h4>
      </div>
    </div>
    </div>
  )
}
