import React from "react"

export default function BannerOne() {
  return (
    <div className="bannerOne_drones">
      <div>
        <h1>DESARROLLO DE SOFTWARE <br/> PERSONALIZADO</h1>
        <p>En Helios Digital Group tenemos amplia experiencia en el desarrollo de software para asistir a <br/> nuestros clientes</p>
        </div>
    </div>
  )
  
}
