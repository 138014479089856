import React from "react"
import { Col, Container, Row } from "react-bootstrap"

import Widget from "./components/widget"

import About from "./style"
export default function AboutSection() {
  return (
    <About className="about_container" backgroundColor="#F7F7F7">
      <Container>
        <Row className="align-items-center justify-content-center">
          <div className="col-3">
            <Widget
              directionXS=""
              title="Servidores y escritorios virtuales."
              icon="fas fa-server"
            />
          </div>
          <div className="col-3">
            <Widget
              directionXS=""
              title="Paga por los que consumes."
              icon="fas fa-file-invoice"
            />
          </div>
          <div className="col-3">
            <Widget
              directionXS=""
              title="Alta Disponibilidad y seguridad de tu información."
              icon="fas fa-info-circle"
            />
          </div>
          <div className="col-3">
            <Widget
              directionXS=""
              title="Solución de recuperación ante desastres."
              icon="fa fa-cog"
            />
          </div>
        </Row>
        <div className="row_parthers">
          <img src="https://res.cloudinary.com/dv10simqm/image/upload/v1642124626/helios/Desarrollo%20de%20Software/azure_2x_vqeil7.png" />
          <img src="https://res.cloudinary.com/dv10simqm/image/upload/v1642124626/helios/Desarrollo%20de%20Software/aws_2x_j4rrps.png" />
          <img src="https://res.cloudinary.com/dv10simqm/image/upload/v1642124626/helios/Desarrollo%20de%20Software/googlecloud_2x_svcyp6.png" />
        </div>
      </Container>
    </About>
  )
}
