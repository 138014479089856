import React from "react"
import { PageWrapper } from "~components/Core"

import FooterOne from "~sections/marketing/FooterOne"
import BannerOne from "~sections/DesarrolloDeSoftware/BannerOne"
import ServicesSection from "~sections/DesarrolloDeSoftware/Services"
import Chart from "~sections/DesarrolloDeSoftware/Chart"
import AboutSection from "~sections/DesarrolloDeSoftware/About"
import Certification from "~sections/DesarrolloDeSoftware/Certification"
import ProcessSection from "~sections/DesarrolloDeSoftware/Process"
import BlogSection from "~sections/DesarrolloDeSoftware/Blog"


const header = {
  headerClasses: "site-header site-header--menu-start dark-header site-header--sticky",
  containerFluid: true,
  // customLogo: Images.HeaderLogo,

}

export default function DesarrolloDeSoftware() {
  return (
    <PageWrapper headerConfig={header}>
      <BannerOne />
      <ServicesSection />
      <Chart />
      <AboutSection />
      <Certification />
      <ProcessSection />
      {/* <BlogSection /> */}
      <FooterOne />
    </PageWrapper>
  )
}
