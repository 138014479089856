import React from "react"

export default function Certification() {
    return (
        <div className="container container_certification">
            <div className="row">
                <div className="col-xs-12 col-lg-6 info_certification">
                    <div>
                        <h1>Certificaciones</h1>
                        <p>Contamos con profesionales con amplia <br/> experiencia y certificaciones.</p>
                        <div className="row_img">
                            <img src="https://res.cloudinary.com/dv10simqm/image/upload/v1642124818/helios/Desarrollo%20de%20Software/blueprism_2x_ex2txa.png" />
                            <img src="https://res.cloudinary.com/dv10simqm/image/upload/v1642124817/helios/Desarrollo%20de%20Software/microsoft_gp_2x_cn4jiz.png" />
                            <img src="https://res.cloudinary.com/dv10simqm/image/upload/v1642124817/helios/Desarrollo%20de%20Software/pmi_2x_pflufv.png" />
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-lg-6">
                    <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1642030921/helios/Desarrollo%20de%20Software/certificaciones_2x_geeee9.png" />
                </div>
            </div>
        </div>
    )
}
